import { render, staticRenderFns } from "./PlainHtml1.vue?vue&type=template&id=16bda4b3"
import script from "./PlainHtml1.vue?vue&type=script&lang=ts"
export * from "./PlainHtml1.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports